import { Component, Input, Inject, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { NgIf, CommonModule } from '@angular/common';
import { MatDialog, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon';
import { fromEvent } from "rxjs";
// Model 3D
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader.js";
import { DataTexture } from "three";
import { USDZInstance } from "three-usdz-loader/lib/USDZInstance";
import { USDZLoader } from "three-usdz-loader";
import { Line2 } from 'three/examples/jsm/lines/Line2.js';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry.js';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial.js';
import {
  CSS2DRenderer,
  CSS2DObject,
} from "three/examples/jsm/renderers/CSS2DRenderer.js";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-img-preview',
  templateUrl: './img-preview.component.html',
  styleUrls: ['./img-preview.component.scss']
})
export class ImgPreviewComponent {
  // Inputs
  @Input() name = '';
  @Input() fileid = '';
  @Input() previewUrl = '';
  @Input() thumbUrl = '';
  @Input() modelUrl = '';
  @Input() isImage = false;

  constructor(public dialog: MatDialog) { }

  openDialog() {
    this.dialog.open(DialogContentExampleDialog, {
      data: {
        name: this.name,
        fileid: this.fileid,
        previewUrl: this.previewUrl,
        isImage: this.isImage,
        modelUrl: this.modelUrl,
      },
      panelClass: 'fullscreen-dialog',
      height: '100vh',
      width: '100%',
      maxWidth: '100%'
    });
  }
}

@Component({
  selector: 'modal-img-preview',
  templateUrl: 'modal.component.html',
  styleUrls: ['./img-preview.component.scss'],
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    NgIf,
    CommonModule],
})
export class DialogContentExampleDialog implements AfterViewInit, OnDestroy {
  fullscreen = false;

  handleFullScreen(): void {
    const modalElements = document.getElementsByClassName("mdc-dialog__container");
    if (modalElements) {
      const modalElement = modalElements.item(0) as HTMLDivElement;

      if (!this.fullscreen) {
        modalElement.className = "mdc-dialog__container fullscreen"
      } else {
        modalElement.className = "mdc-dialog__container"
      }
      this.fullscreen = !this.fullscreen;
    }
  }

  // Start life cycle management
  ngAfterViewInit(): void {
    if (this.data.isImage) {
      fromEvent<MouseEvent>(this.image.nativeElement, "mousemove").subscribe((ev: MouseEvent) => {
        const rect = this.image.nativeElement.getBoundingClientRect();
        this.mouseX = ((ev.clientX - rect.left) / rect.width) * 100;
        this.mouseY = ((ev.clientY - rect.top) / rect.height) * 100;
      });

      fromEvent(window, "wheel").subscribe((ev: WheelEvent | any) => {
        const newScale = this.scale - ev.deltaY * 0.2;
        this.scale = Math.max(newScale, 100);
        this.top = this.mouseY;
        this.left = this.mouseX;
      });
    } else {
      this.mounted();
    }
  }

  ngOnDestroy(): void {
    if (!this.data.isImage) {
      // Cancel the animation
      if (this.animationId !== null) {
        cancelAnimationFrame(this.animationId);
      }

      // Remove all objects from the scene
      while (this.scene.children.length > 0) {
        this.scene.remove(this.scene.children[0]);
      }

      // Free up renderer memory
      this.renderer.dispose();
    }
  }
  // End life cycle management

  // Start Image Control
  @ViewChild("image", { static: false }) image: ElementRef<HTMLImageElement> | any;
  scale = 100;
  top = 0;
  left = 0;
  mouseX = 0;
  mouseY = 0;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }
  // End Image Control

  // Function to download a file
  async downloadFile(): Promise<void> {
    try {
      const url = this.data.isImage ? this.data.previewUrl : this.data.modelUrl;
      // Fetch the file
      const response = await fetch(url);

      // Check if the request was successful
      if (response.status !== 200) {
        throw new Error(
          `Unable to download file. HTTP status: ${response.status}`
        );
      }

      // Get the Blob data
      const blob = await response.blob();

      // Create a download link
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = `${this.data.fileid}.${this.data.isImage ? 'jpg' : 'usdz'}`;

      // Trigger the download
      document.body.appendChild(downloadLink);
      downloadLink.click();

      // Clean up
      setTimeout(() => {
        URL.revokeObjectURL(downloadLink.href);
        document.body.removeChild(downloadLink);
      }, 100);
    } catch (error: any) {
      console.error("Error downloading the file:", error.message);
    }
  }

  // Start Model 3D Control
  @ViewChild('threeContainer', { static: true }) threeContainer: ElementRef | any;
  scene!: THREE.Scene;
  lineScene!: THREE.Scene;
  camera!: THREE.PerspectiveCamera;
  renderer!: THREE.WebGLRenderer;
  controls!: OrbitControls;
  private animationId: number | null = null;

  // Variables to store the clicked points
  pickableObjects: THREE.Mesh[] = [];
  allContainers: any;
  line: THREE.Line | null = null;
  distanceText: THREE.Sprite | null = null;
  point1: THREE.Vector3 | null = null;
  point2: THREE.Vector3 | null = null;

  // Tells if a file is loading currently
  modelIsLoading = false;

  isShiftPressed = false;
  isMouseDown = false;
  //ctrlNoteDown = false;

  // Loaded models
  loadedModels: USDZInstance[] = [];

  // USDZ loader instance. Only one should be instantiated in the DOM scope
  loader!: USDZLoader;

  // Simple error handling
  error: string | null = null;

  // Notes controls
  labelRenderer = new CSS2DRenderer();
  note = "";
  annotations: any = [];
  notesLabels: { [key: number]: CSS2DObject } = {};
  noteId = 0;
  notePosition = {};
  disableSave = true;
  isTyping = false;

  async mounted(): Promise<void> {
    // Get Notes
    await fetch(
      `${environment.API_URL}/api/GetNotesByFileid?fileid=${this.data.fileid}`
    )
      .then((response) => response.json())
      .then((json) => {
        json.notes.forEach((item: any) => {
          this.annotations.push(item);
        });
      });

    // Setup camera
    this.camera = new THREE.PerspectiveCamera(
      27,
      window.innerWidth / window.innerHeight,
      1,
      3500
    );
    this.camera.position.z = 7;
    this.camera.position.y = 7;
    this.camera.position.x = 0;

    // Setup scene
    this.scene = new THREE.Scene();
    this.lineScene = new THREE.Scene();
    this.scene.remove();
    this.scene.background = new THREE.Color(0xffffff);

    // Setup light
    const ambiantLight = new THREE.AmbientLight(0x111111);
    ambiantLight.intensity = 1;
    this.scene.add(ambiantLight);

    // Setup main scene
    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      preserveDrawingBuffer: false,
    });
    this.renderer.autoClear = true;
    this.renderer.setPixelRatio(window.devicePixelRatio * 2);
    // Set Custom size
    this.renderer.setSize(500, 500);
    this.renderer.toneMapping = THREE.CineonToneMapping;
    this.renderer.toneMappingExposure = 2;
    this.renderer.shadowMap.enabled = false;
    this.renderer.shadowMap.type = THREE.VSMShadowMap;

    // Setup cubemap for reflection
    await new Promise((resolve) => {
      const pmremGenerator = new THREE.PMREMGenerator(this.renderer);
      pmremGenerator.compileCubemapShader();
      new RGBELoader().load(
        "assets/studio_country_hall_1k.hdr",
        (texture: DataTexture) => {
          texture.dispose();
          const hdrRenderTarget = pmremGenerator.fromEquirectangular(texture);

          texture.mapping = THREE.EquirectangularReflectionMapping;
          texture.needsUpdate = true;
          window.envMap = hdrRenderTarget.texture;
          resolve(true);
        }
      );
    });

    //Add the canvas to the document
    this.threeContainer.nativeElement.appendChild(this.renderer.domElement);

    this.initInternalControls();

    // Setup navigation
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.enableDamping = true;
    this.controls.update();

    // Setup main animation update loop
    this.animate();

    // Setup the USDZ loader
    this.loader = new USDZLoader("assets/wasm");

    // Setup windows events
    window.addEventListener("resize", this.onWindowResize);

    await fetch(this.data.modelUrl)
      .then((res) => res.blob())
      .then((myBlob) => {
        const myFile = new File([myBlob], "model-mobile.usdz", {
          type: myBlob.type,
        });

        setTimeout(() => {
          this.loadFile(myFile);
          this.animate();
          this.loadModelNotes();
        }, 2000);
      });
  }

  /**
   * Main update loop
   */
  async animate(): Promise<void> {
    this.renderer.render(this.scene, this.camera);
    this.renderer.autoClear = false;
    this.renderer.clearDepth();
    this.renderer.render(this.lineScene, this.camera);
    this.labelRenderer.render(this.scene, this.camera);
    this.animationId = requestAnimationFrame(this.animate.bind(this));
  }

  /**
   * Load a USDZ file in the scene
   * @param file
   * @returns
   */
  async loadFile(file: File): Promise<void> {
    // Prevents multiple loadings in parallel
    if (this.modelIsLoading) {
      return;
    }

    // Notice model is now loading
    this.modelIsLoading = true;

    // Reset any previous error
    this.error = null;

    // Clearup any previsouly loaded model
    // We could technically load multiple files by removing this for loop
    for (const el of this.loadedModels) {
      el.clear();
    }
    this.loadedModels = [];

    // Create the ThreeJs Group in which the loaded USDZ model will be placed
    const group = new THREE.Group();
    this.scene.add(group);

    // Load file and catch any error to show the user
    try {
      const loadedModel: any = await this.loader.loadFile(file, group);
      //this.model = group;
      // Get Mesh for pickableObjects - Measurements Controls
      const mesh: THREE.Mesh =
        loadedModel.renderInterface.meshes["/ObjectCapture/Geometry/Mesh"]
          ._mesh;
      this.pickableObjects.push(mesh);
      this.loadedModels.push(loadedModel);
    } catch (e) {
      this.error = e as string;
      console.error("An error occured when trying to load the model" + e);
      this.modelIsLoading = false;
      return;
    }

    // Fits the camera to match the loaded model
    this.allContainers = this.loadedModels.map((el: USDZInstance) => {
      return el.getGroup();
    });
    this.fitCamera(this.camera, this.controls, this.allContainers);

    // Notice end
    this.modelIsLoading = false;
  }

  /**
   * Fits the camera view to the imported objects
   */
  fitCamera(
    camera: THREE.PerspectiveCamera,
    controls: OrbitControls,
    selection: THREE.Group[],
    fitOffset = 1.5
  ): void {
    const cam = camera as THREE.PerspectiveCamera;
    const size = new THREE.Vector3();
    const center = new THREE.Vector3();
    const box = new THREE.Box3();

    box.makeEmpty();
    for (const object of selection) {
      box.expandByObject(object);
    }

    box.getSize(size);
    box.getCenter(center);

    const maxSize = Math.max(size.x, size.y, size.z);
    const fitHeightDistance =
      maxSize / (2 * Math.atan((Math.PI * cam.fov) / 360));
    const fitWidthDistance = fitHeightDistance / cam.aspect;
    const distance = fitOffset * Math.max(fitHeightDistance, fitWidthDistance);

    const direction = controls.target
      .clone()
      .sub(cam.position)
      .normalize()
      .multiplyScalar(distance);

    controls.maxDistance = distance * 10;
    controls.target.copy(center);

    cam.near = distance / 100;
    cam.far = distance * 100;
    cam.updateProjectionMatrix();

    camera.position.copy(controls.target).sub(direction);

    controls.update();
  }

  onWindowResize(): void {
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.labelRenderer.setSize(window.innerWidth, window.innerHeight);
  }

  handleFilesUpload(files: FileList): void {
    this.loadFile(files[0]);
  }

  unmounted(): void {
    console.log("unmounted===>");
  }

  createAnnotation(position: any, note = ""): void {
    this.noteId++;
    const noteDiv = document.createElement("div") as HTMLDivElement;
    noteDiv.id = `${this.noteId}-note`;
    noteDiv.className = "model-annotation";
    noteDiv.innerText = note;
    if (!note) {
      const blinkElement = document.createElement("span") as HTMLSpanElement;
      blinkElement.className = "blinking-cursor";
      blinkElement.innerText = "|";
      noteDiv.appendChild(blinkElement);

      const noteInput = document.createElement("input") as HTMLInputElement;
      noteInput.type = "text";
      noteInput.id = `${this.noteId}-input`;
      noteInput.style.position = "absolute";
      noteInput.style.left = "-9999px";
      noteInput.style.top = "-9999px";
      // noteInput.value = "Test";
      noteInput.oninput = (event: any) => {
        this.note = event.target.value;
        noteInput.value = this.note;
        noteDiv.innerText = this.note;
        noteDiv.appendChild(blinkElement);
      };
      noteInput.onkeydown = (event: any) => {
        if (event.key === "Enter") {
          event.preventDefault();

          this.controls.enabled = true;
          blinkElement.remove();
          this.handleNote();
        }
      };
      noteInput.onblur = (event: any) => {
        event.preventDefault();

        this.controls.enabled = true;
        blinkElement.remove();
        this.handleNote();
      };

      this.threeContainer.nativeElement.appendChild(noteInput);
      noteInput.focus();
    }

    const noteLabel = new CSS2DObject(noteDiv);
    noteLabel.position.set(position.x, position.y, position.z);
    this.notesLabels[this.noteId] = noteLabel;
    this.notesLabels[this.noteId].position.lerpVectors(position, position, 0.5);
    this.scene.add(this.notesLabels[this.noteId]);
  }

  // measurements and annotations controls
  async initInternalControls(): Promise<void> {
    // Create a measurement line

    // Line2
    const lineGeometry = new LineGeometry();
    const lineMaterial = new LineMaterial({
      color: 0x00FF00,
      linewidth: 8,
      resolution: new THREE.Vector2(window.innerWidth, window.innerHeight), // Resolution for line attenuation
    });
    const line = new Line2(lineGeometry, lineMaterial);
    //line.scale.set(1, 1, 1);

    // add line
    this.lineScene.add(line);

    // Create a text element to display the measurement
    const textElement = document.createElement("div");
    textElement.style.position = "absolute";
    textElement.style.color = "black";
    textElement.style.fontSize = "16px";
    textElement.style.top = "10px";
    textElement.style.left = "10px";
    const displayDiv = document.getElementById("display-container");
    displayDiv!.appendChild(textElement);

    // Initialize variables to store selected points
    let startPoint: THREE.Vector3 | null = null;
    let endPoint: THREE.Vector3 | null = null;

    // Event listeners for mouse interactions
    const raycaster = new THREE.Raycaster();

    // Get the canvas element
    const canvas = this.renderer.domElement;

    // Render label canvas
    this.labelRenderer.setSize(500, 500);
    this.labelRenderer.domElement.id = "labels";
    this.labelRenderer.domElement.style.position = "absolute";
    this.labelRenderer.domElement.style.width = "100%";
    this.labelRenderer.domElement.style.height = "100%";
    this.labelRenderer.domElement.style.top = "0px";
    this.labelRenderer.domElement.style.pointerEvents = "none";
    this.labelRenderer.domElement.style.overflow = "unset";
    this.threeContainer.nativeElement.appendChild(this.labelRenderer.domElement);

    // Function to calculate normalized device coordinates from mouse event
    const getNormalizedDeviceCoordinates = (event: any) => {
      const rect = canvas.getBoundingClientRect();
      const x = ((event.clientX - rect.left) / canvas.clientWidth) * 2 - 1;
      const y = -((event.clientY - rect.top) / canvas.clientHeight) * 2 + 1;
      return { x, y };
    };

    // Function to handle mouse down event
    const onMouseDown = (event: any) => {
      event.preventDefault();
      if (event.shiftKey) {
        this.isShiftPressed = true;
        // set start point
        const normalizedCoords = getNormalizedDeviceCoordinates(event);
        // Cast a ray from the camera through the mouse position
        raycaster.setFromCamera(normalizedCoords, this.camera);
        // Get the intersection point with the 3D model
        const intersects = raycaster.intersectObjects(this.pickableObjects);
        if (intersects.length > 0) {
          startPoint = intersects[0].point;
          this.isMouseDown = true;
          this.isTyping = true;
          setTimeout(() => {
            if (!this.isMouseDown) {
              // handle click
              this.notePosition = { ...startPoint };
              this.createAnnotation(startPoint);
              // this.animate();
            }
          }, 250);
        }
      } else {
        const input = document.getElementById(
          `${this.noteId}-input`
        ) as HTMLInputElement;
        if (input) {
          input.blur();
          const noteDiv = document.getElementById(
            `${this.noteId}-note`
          ) as HTMLDivElement;
          if (this.note == "" && noteDiv) {
            noteDiv.remove();
          }
        }
      }
    };

    // Function to handle mouse move event
    const onMouseMove = (event: any) => {
      if (this.isShiftPressed && this.isMouseDown) {
        this.isTyping = false;
        // Calculate the mouse position in normalized device coordinates

        const normalizedCoords = getNormalizedDeviceCoordinates(event);

        // Cast a ray from the camera through the mouse position
        raycaster.setFromCamera(normalizedCoords, this.camera);

        // Get the intersection point with the 3D model
        const intersects = raycaster.intersectObjects(this.pickableObjects);

        if (intersects.length > 0) {
          endPoint = intersects[0].point;

          // Calculate the distance in CM
          const distanceCM = startPoint!.distanceTo(endPoint) * 100; // Assuming 1 unit = 1 meter

          // Update the measurement line
          const lineVertices = [
            startPoint!.x,
            startPoint!.y,
            startPoint!.z,
            endPoint.x,
            endPoint.y,
            endPoint.z,
          ];

          // Line2
          lineGeometry.setPositions(lineVertices);
          line.computeLineDistances();

          // Update the text display
          textElement.textContent = `Distance: ${distanceCM.toFixed(2)} cm`;
        }
      }
    };

    // Function to handle mouse up event
    const onMouseUp = () => {
      this.isMouseDown = false;
    };

    window.addEventListener("mousedown", onMouseDown, false);
    window.addEventListener("mousemove", onMouseMove, false);
    window.addEventListener("mouseup", onMouseUp, false);

    window.addEventListener("keydown", (event) => {
      if (event.key === "Shift") {
        this.controls.enabled = false;
        this.renderer.domElement.style.cursor = "crosshair";
      }
    });

    window.addEventListener("keyup", (event) => {
      if (event.key === "Shift") {
        if (!this.isTyping) {
          this.controls.enabled = true;
        }
        this.renderer.domElement.style.cursor = "pointer";
      }
    });

    this.animate();
  }

  loadModelNotes(): void {
    setTimeout(() => {
      this.annotations.forEach((item: any) => {
        // If the note contains a position, add it to the model
        if (item.position) {
          this.createAnnotation(item.position, item.note);
        }
      });
      this.animate();
    }, 1000);
  }

  handleNote(): void {
    if (this.note) {
      fetch(`${environment.API_URL}/api/CreateAnnotation`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fileid: this.data.fileid,
          note: this.note,
          position: this.notePosition,
        }),
      })
        .then((response) => response.json())
        .then((json) => {
          console.log("Create Note===>", json);
        });
      this.annotations.push({ note: this.note });
    }
    this.note = "";
    this.notePosition = {};
  }
}
