import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-models',
  templateUrl: './models.component.html',
  styleUrls: ['./models.component.scss']
})
export class ModelsComponent implements OnInit {
  modelsArray: any[] = [];

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.mounted();
  }

  async mounted(): Promise<void> {
    await fetch(`${environment.API_URL}/api/GetAllModels`)
      .then((response) => response.json())
      .then(async (json) => {
        const blobList = json.files;
        // Get images from virtual directory
        let tempListPhotos: any[] = [];

        await fetch(`${environment.API_URL}/api/GetAllPhotos`)
          .then((response) => response.json())
          .then((json) => {
            tempListPhotos = json.files;
          });

        blobList.forEach(async (blob: any) => {
          // Extract the unique identifier from the URL
          const scanid = blob.scanid;
          let tempImage: any = tempListPhotos.find(
            (item: any) =>
              item.scanid === scanid && item.name.includes("_thumb")
          );

          if (!tempImage) {
            tempImage = tempListPhotos.find(
              (item: any) => item.scanid === scanid
            );
          }

          this.modelsArray.push({
            modelUrl: blob.url,
            previewUrl: tempImage ? tempImage.url : "",
            thumbUrl: tempImage ? tempImage.url : "",
            scanid,
            fileid: blob.id,
          });
        });
      });
  }
}
