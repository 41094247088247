import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-photos',
  templateUrl: './photos.component.html',
  styleUrls: ['./photos.component.scss']
})
export class PhotosComponent implements OnInit {
  photosUrlArray: any[] = [];
  scanGroups: any[] = [];
  filterScanGroups: any[] = [];
  tempPhotos: any[] = [];
  tempBreadcumb = "";
  query = "";

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.mounted();
  }

  async mounted(): Promise<void> {
    await fetch(`${environment.API_URL}/api/GetAllPhotos`)
      .then((response) => response.json())
      .then((json) => {
        const blobList = json.files;
        let tempPatientCount = 111;

        const blobThumbList = blobList.filter((item: any) =>
          item.name.includes("_thumb")
        );
        const blobPhotosList = blobList.filter(
          (item: any) => !item.name.includes("_thumb")
        );

        blobPhotosList.forEach(async (blob: any) => {
          // Extract the unique identifier from the URL
          const scanId = blob.scanid;
          const findGroup = this.scanGroups.find(
            (item: any) => item.scanId === scanId
          );
          const fileName = blob.name.split(".");
          const foundThumb = blobThumbList.find((item: any) =>
            item.name.includes(fileName[0])
          );

          if (!findGroup) {
            this.scanGroups.push({
              patientId: tempPatientCount,
              scanId,
            });

            tempPatientCount += 111;
          }

          if (!blob.url.includes(".HEIC")) {
            this.photosUrlArray.push({
              url: blob.url,
              thumbUrl: foundThumb ? foundThumb.url : blob.url,
              scanId,
              fileid: blob.id,
            });
          }
        });
      });
    this.filterScanGroups = this.scanGroups;
  }

  getGallery(scanId: string): void {
    const tempGallery: any[] = this.photosUrlArray.filter(
      (item: any) => item.scanId === scanId
    );

    this.tempBreadcumb = scanId;
    this.tempPhotos = tempGallery;
  }

  cleanGallery(): void {
    this.tempBreadcumb = "";
    this.tempPhotos = [];
  }

  handleSearch(): void {
    console.log("search===>");
    const searchString = this.query.toLowerCase();
    const tempSearch = this.scanGroups.filter(
      (item: any) =>
        typeof item.scanId === "string" &&
        item.scanId?.toLowerCase().indexOf(searchString) > -1
    );

    this.filterScanGroups = tempSearch;
  }
}
