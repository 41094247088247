<div class="content">
  <div class="title-breadcumb">
    <h2
      (click)="cleanGallery()"
      class="mat-headline-3 root"
      [class.navigate]="tempBreadcumb"
    >
      Photos
    </h2>
    <mat-icon *ngIf="tempBreadcumb" aria-hidden="false" aria-label="Breadcumb Chevron" fontIcon="chevron_right"></mat-icon>
    <h2 *ngIf="tempBreadcumb" class="mat-headline-3">{{ tempBreadcumb }}</h2>
  </div>

  <mat-form-field *ngIf="!tempBreadcumb" appearance="fill" class="transparent-input">
    <mat-label>Search</mat-label>
    <input matInput placeholder="Enter ScanID" [(ngModel)]="query" (input)="handleSearch()">
    <mat-icon matSuffix>search</mat-icon>
  </mat-form-field>
  
  <div
    *ngIf="tempPhotos.length === 0"
    class="layout"
  >
    <div
      class="item group-item"
      *ngFor="let item of filterScanGroups"
      (click)="getGallery(item.scanId)"
    >
      <div class="icon">
        <img src="../../../../../assets/Kidney.svg" alt="Kidney Icon" />
      </div>
      <div class="info-item">
        <h4>Scan ID: {{ item.scanId }}</h4>
        <span>Patient ID: {{ item.patientId }}</span>
      </div>
    </div>
  </div>
  
  <div
    *ngIf="tempPhotos.length > 0"
    id="photos-container"
    class="layout"
  >
    <div
      class="item photo"
      *ngFor="let item of tempPhotos"
    >
      <app-img-preview
        [previewUrl]="item.url"
        [thumbUrl]="item.thumbUrl"
        [fileid]="item.fileid"
        [isImage]="true"
      />
    </div>
  </div>
</div>