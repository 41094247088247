<div class="img-preview" [class.small]="name">
  <img
    *ngIf="previewUrl === ''"
    src="../../../../../assets/ar_quick_look.png"
    alt="Preview"
    (click)="openDialog()"
  />

  <img
    crossorigin="anonymous"
    *ngIf="thumbUrl !== ''"
    [src]="thumbUrl"
    alt="Preview"
    (click)="openDialog()"
  />

  <span *ngIf="name !== ''" class="md-subheading">{{ name }}</span>
</div>