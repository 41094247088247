import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';

//Components
import { ModelsComponent } from './screens/models/models.component';
import { PhotosComponent } from './screens/photos/photos.component';

const routes: Routes = [
	{ path: '', redirectTo: 'models', pathMatch: 'full' },
	{ path: 'models', component: ModelsComponent },
	{ path: 'photos', component: PhotosComponent },
];

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
	],
	exports: [RouterModule],
})
export class AppRoutingModule { }
