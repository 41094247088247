<div class="content">
  <h2 class="mat-headline-3">3D Models</h2>
  
  <div class="layout">
    <div class="item" *ngFor="let item of modelsArray; index as i">
      <app-img-preview
          [modelUrl]="item.modelUrl"
          [previewUrl]="item.previewUrl"
          [thumbUrl]="item.thumbUrl"
          [name]="'Scan ID: ' + item.scanid"
          [fileid]="item.fileid"
        />
    </div>
  </div>
</div>