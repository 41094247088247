export const environment = {
	API_URL: "https://unos-sienna-api.azurewebsites.net",
	BASE_URL: "https://dev.sienna.shockoe.dev",
	CONTAINERS: {
		PHOTOS: "photos",
		MODELS: "3d-models",
		OBJECTS: "ar-objects",
	},
	description: "Dev",
};
