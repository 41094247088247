import { Component } from '@angular/core';

declare global {
  interface Window {
    envMap: any; // turn off type checking
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

}
