<h2 *ngIf="!data.isImage" mat-dialog-title>3D Model - Preview</h2>
<h2 *ngIf="data.isImage" mat-dialog-title>Photos - Preview</h2>

<mat-dialog-content class="mat-typography">
  <h3 *ngIf="!data.isImage">{{ data.name }}</h3>

  <p>You can zoom in and out by scrolling with your mouse or touchpad.</p>

  <p *ngIf="!data.isImage">Press <b>SHIFT</b> + <b>Left Click and hold</b> to start measuring
    using a line. <br />Continue to hold <b>SHIFT</b> and
    <b>Left Click</b> to mark the end of the line.</p>

    <div *ngIf="data.isImage" class="image-container">
      <img 
        crossorigin="anonymous"
        alt="Preview"
        [src]="data.previewUrl"
        [ngStyle]="{
          transform: 'scale(' + scale / 100 + ')',
          'transform-origin': left + '% ' + top + '%'
        }" #image>
    </div>

    <div class="preview-container">
      <div class="three-container" #threeContainer>
        <div class="three-overlay" id="display-container">
          <div *ngIf="error != null" class="alert">
            An error occured when loading the USDZ file. Maybe this file is not
            supported or the loader is not supported on this device.
          </div>
        </div>
      </div>
  
      <div *ngIf="!data.isImage" class="annotations">
        <p class="md-body-1">
          Press <b>SHIFT</b> + <b>Left Mouse Click</b> to position the note at a
          point in the model. Then proceed to write your note.<br />To save the
          note press <b>Enter</b>
        </p>
  
        <div class="box" *ngFor="let item of annotations">
          <span class="md-body-1">{{ item.note }}</span>
        </div>
      </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button color="primary" (click)="downloadFile()">
    Download
    <mat-icon aria-hidden="false" aria-label="File Download" fontIcon="file_download"></mat-icon>
  </button>

  <button mat-button color="primary" (click)="handleFullScreen()">
    Fullscreen
    <mat-icon aria-hidden="false" aria-label="Fullscreen" fontIcon="fullscreen"></mat-icon>
  </button>

  <button mat-button mat-dialog-close color="primary">Close</button>
</mat-dialog-actions>
